svg:not(:root)
  display: block

noscript
  background: $red
  display: block
  color: $off-white
  width: 100%
  padding: 2px 5px
  text-align: center
  font-size: 12px

#app
  > .app-content
    > .view-wrap:not(.page-element)
      > .view-content
        min-height: 92vh

  &:not(.bg-takeover)
    > .app-content
      > .view-wrap:not(.page-element)
        > .view-content:not(.bg-override)
          background-color: $bg-regular

  &.no-scroll
    +media('sm')
      overflow: hidden !important
      height: 100vh !important
      width: 100% !important

.view-wrap
  padding-top: 87px

  +media('sm')
    padding-top: 0

.visually-hidden, .skip-link-hidden:not(:focus):not(:active)
  position: absolute !important
  height: 1px
  width: 1px
  overflow: hidden
  clip-path: inset(100%)/*makes sure the outline drawn by :focus is not visible and the element cannot be clicked on*/
  clip: rect(1px, 1px, 1px, 1px)/* deprecated implementation of clip-path for browsers that don't yet support this feature */
  white-space: nowrap/* prevents separate words from being incorrectly concatenated by screen readers */

body
  font-family: Montserrat, Helvetica, Arial, sans-serif
  font-style: normal
  font-weight: 400
  font-size: ($base)px
  letter-spacing: -0.2px
  color: $body-copy
  background: $white
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  opacity: 0
  animation: fadeIn
  animation-delay: 0.2s
  animation-duration: 0.3s
  animation-fill-mode: forwards

  +media('md')
    font-size: ($base*0.9)px

  +media('sm')
    font-size: ($base*0.8)px

  /* Heading */
  .h0, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6
    font-family: Montserrat, Trebuchet, sans-serif
    font-style: normal
    font-weight: 600
    color: $header-copy
    letter-spacing: normal
    margin-top: 0
    margin-bottom: 0.3em
    line-height: 1.2em

  .h0
    font-size: $h0-em

  h1, .h1
    font-size: $h1-em

    +media('sm')
      font-size: $h2-em

  h2, .h2
    font-size: $h2-em

    +media('sm')
      font-size: $h3-em

  h3, .h3
    font-size: $h3-em

    +media('md')
      font-size: $h4-em

  h4, .h4
    font-size: $h4-em

  h5, .h5
    font-size: $h5-em

  h6, .h6
    font-size: $h6-em

  p
    line-height: 1.5em
    margin-top: 0
    margin-bottom: 0.4em

  p
    a
      text-decoration: underline
  a
    text-decoration: none
    color: $link-regular

    &.dark
      color: $mid-dark-grey

      &:hover, &:active, &:visited
        color: darken($mid-dark-grey, 5%)

  .bold, strong
    font-weight: 600

  .caps
    text-transform: capitalize

  .email
    word-break: break-word

  label
    font-weight: 400
  
  .white-text
    color: $white

  .standard-text
    color: $body-copy

  .accent-text
    color: $accent

  .green-text
    color: $green

  .red-text
    color: $red

  .hidden-text
    width: 0px
    height: 0px
    display: inline-block
    overflow: hidden
    text-indent: -1000px

  .fine-print
    font-size: 12px

  .fine-print-lg
    font-size: 13px

  .small
    font-size: 14px

  .coupon-applied
    color: $red
    text-decoration: line-through

  .small-copy
    font-size: $sm-em

  .alert
    font-size: 16px
    padding: 5px 10px
    margin-bottom: 25px
    border: 1px solid transparent
    border-radius: 4px

    p
      margin-bottom: 0px

    &.alert-danger
      color: $white
      border: 1px solid $red
      background-color: $red
      a, a:hover
        color: $white

    &.alert-warning
      color: #8a6d3b
      border: 1px solid #CAAD16
      background-color: #fcf8e3
      padding: 15px
      a, a:hover
        color: #8a6d3b

    &.alert-success
      color: darken($green, 40%)
      border: 1px solid $green
      background-color: $light-green
      a, a:hover
        color: $green


    &.alert-success, &.alert-warning, &.alert-danger
      a
        color: inherit
        font-weight: 600

  .notice
    border: 1px solid $mid-light-grey
    background-color: $off-white
    border-radius: 10px
    padding: 14px 20px
    margin-top: 20px

    &.danger
      border-color: lighten($red, 30%)
      background-color: lighten($red, 97%)

    p
      &:last-of-type
        margin-bottom: 0px

  .loading-bear
    background-image: url('~@/images/wait@2x.gif')
    background-size: 60px 40px
    width: 60px
    height: 40px
    margin: 20px auto

  /* Hyperlink */
  a
    color: $link-regular
    text-decoration: none

    &:hover
      color: $link-regular
      cursor: pointer
      text-decoration: underline

  img
    width: 100%
    max-width: 100%

  .bg-blue
    background-color: $bg-blue

  .bg-alt
    background-color: $bg-alt

    h1, h2, h3, h4, h5, h6, p, a
      color: $white

  .bg-light
    background-color: $bg-light

  .bg-dark
    background-color: $bg-dark

  .bg-regular
    background-color: $bg-regular

  .bg-black
    background-color: black


  .bg-space
    background-color: $charcoal
    background-image: url('~@/images/bear_stars.svg')
    background-repeat: repeat
    background-size: 360px 360px
    background-attachment: fixed

    h1, h2, h3, h4, h5, h6, p, li
      color: $white

  .element
    display: block
    width: 100%

    &.large
      max-width: 950px

    &.medium
      max-width: 800px

    &.small
      max-width: 640px

    &.xs
      max-width: 480px

    &.centered
      margin: 0 auto

  section
    padding: 90px 20px

    &.no-padding
      padding: 0

    &:not(.no-padding)
      &.smaller
        padding: 45px 20px

        +media('sm')
          padding: 30px 10px

      +media('sm')
        padding: 45px 10px

    &.smaller
      padding: 45px 20px

      +media('sm')
        padding: 30px 10px

    +media('sm')
      padding: 45px 10px

    &.bg-blue
      background-color: $bg-blue

    &.bg-dark
      background-color: $bg-dark

    &.bg-regular
      background-color: $bg-regular

    .section-inner
      display: block
      max-width: 1200px
      margin: 0 auto

    .section-inner-md
      display: block
      max-width: 800px
      margin: 0 auto

  .lg-padding-section
    section
      padding-bottom: 150px
      padding-top: 150px

      +media('md')
        padding-bottom: 70px
        padding-top: 70px

  .clearfix
    clearfix()

  .text-center
    text-align: center

  .vertical-center
    display: flex
    align-items: center

    &.column-sm
      +media('sm')
        flex-direction: column

    &.column-md
      +media('md')
        flex-direction: column

    > *
      flex-grow: 1
      flex-basis: auto

  .mtop-10
    margin-top: 10px

  .mtop-20
    margin-top: 20px

  .mtop-30
    margin-top: 30px
  
  .mtop-50
    margin-top: 50px

  .mbottom-10
    margin-bottom: 10px

  .mbottom-20
    margin-bottom: 20px

  .mbottom-30
    margin-bottom: 30px

  .mbottom-50
    margin-bottom: 50px

  .ptop-10
    padding-top: 10px

  .ptop-20
    padding-top: 20px

  .ptop-30
    padding-top: 30px

  .pbottom-10
    padding-bottom: 10px

  .pbottom-20
    padding-bottom: 20px

  .pbottom-30
    padding-bottom: 30px


  .full-width
    width: 100%

  .retina-block, .retina-inline
    display: none !important

  .visible-xs
    display: none

  .visible-sm
    display: none

  .d-flex
    display: flex

  .d-none
    display: none

  +media('md')
    .visible-sm
      display: block

    .hidden-sm
      display: none

  +media('sm')
    .hidden-xs
      display: none

    .visible-xs
      display: block

  @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)
    .low-res
      display: none !important

    .retina-block
      display: block !important

    .retina-inline
      display: inline !important
