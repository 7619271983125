@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

body
  .bg-takeover
    .nav-wrap
      nav
        background-color: transparent

      &:not(.scrolled)
        nav.tron
          a
            color: white

          .btn
            background-color: #6ec8c4
            border-color: #6ec8c4
            color: black

            &:hover
              opacity: 0.9

          a.logo
            img
              filter: brightness(200%)

  .nav-wrap.scrolled
    nav
      background-color: $white
      border-bottom: 1px solid $grey

.hello-bar-block
  position: relative
  display: block
  width: 100%
  padding: 25px 0
  max-height: 50px

  +media('md')
    display: none

nav
  width: 100%
  z-index: 100
  position: fixed
  top: 0
  left: 0
  background-color: $bg-light
  border-bottom: 1px solid transparent
  transition: border-bottom 0.3s ease, background-color 0.3s ease

  +media('md')
    position: relative

  a:not(.btn), button.plain
    color: $nav-link
    text-decoration: none
    line-height: 1.6em
    font-weight: 500

    &:hover, &:focus
      color: $accent
      text-decoration: none !important

    &.current
      font-weight: 600
      color: $accent

  .current a
    font-weight: 600
    color: $accent

  .logo
    display: block
    width: 193px
    line-height: 1em !important

    +media('sm')
      width: 35px

    img
      margin-left: 10px
      width: 100%

      +media('md')
        margin-left: 0

    &.teams
      +media('md')
        width: 160px
        margin: auto

  .nav-inner
    width: 100%
    max-width: 1340px
    padding: 0 20px
    margin: 0 auto
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: space-between
    align-items: center
    height: 86px

    &.minimal
      display: none

    +media('md')
      padding: 0
      justify-content: space-evenly
      height: auto

  .top-bar
    text-align: left
    width: 260px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

    +media('md')
      width: 100%
      padding: 16px 20px

  .drawer
    position: relative
    width: 100%
    height: auto
    padding: 0
    margin: 0 0 0 auto

    +media('md')
      position: fixed
      top: 0
      right: 0
      width: 100%
      height: 100%
      max-width: 0
      transition-delay: 0.2s
      transition: max-width 0 ease-in-out

    .screen
      display: none

      +media('md')
        display: none
        position: absolute
        top: 0
        left: 0
        content: ''
        width: 100%
        height: 100%
        background-color: rgba(0,0,0,0.3)
        z-index: -1

    #nav-items
      width: 100%
      padding: 0
      display: flex
      flex-wrap: nowrap
      flex-direction: row
      margin: 0 0 0 auto
      justify-content: flex-end
      height: auto

      +media('md')
        position: absolute
        top: 0
        right: -280px
        overflow: hidden
        flex-direction: column
        background: $white
        height: 100%
        padding: 16px 30px 30px
        max-width: 280px
        transition: transform 0.2s ease-in-out

      .mobile-controls
        display: none
        position: relative
        width: 100%

        +media('md')
          display: block

        .close
          position: relative
          right: -10px
          background-image: url('./images/close-x.svg')
          background-size: 26px 26px
          background-position: center
          background-repeat: no-repeat
          width: 32px
          height: 32px
          border-radius: 4px
          padding:3px

          &:hover
            cursor: pointer

      .nav-menu
        display: flex
        flex-direction: row
        align-items: center

        +media('md')
          flex-direction: column
          align-items: flex-start
          flex-grow: 1

      .nav-items-wrap
        display: flex
        flex-direction: row
        align-items: center
        width: 100%

        +media('md')
          flex-direction: column
          align-items: flex-start
          flex-grow: 1
          overflow-y: auto
          max-height: 70vh

      .nav-item
        margin-left: 25px
        width: auto

        +media('md')
          margin-left: 20px

        +media('md')
          margin: 15px 0 0 0
          margin-left: 0 !important
          width: 100%


        &.bottom
          +media('md')
            align-self: flex-end
            margin-top: auto

        a
          +media('md')
            display: block
            width: 100%

          &:not(.btn)
            +media('md')
              color: $nav-link !important

    &.mobile-open
      +media('md')
        max-width: 100%
        transition: none

      .screen
        +media('md')
          display: block

      #nav-items
        +media('md')
          height: 100%
          transform: translateX(-280px)

    .mobile-btn
      margin-bottom: 0

  #mobile-menu
    display: none
    text-decoration: none
    background: url('./images/hammy.svg')
    background-repeat: none
    background-size: 30px 30px
    width: 30px
    height: 30px
    border-radius: 0

    &:hover
      text-decoration: none

    +media('md')
      display: inline-block

.nav-minimal
  .mobile-cta-nav, .nav-inner.complex
    display: none !important

  nav
    position: absolute
    top: 0
    right: 0

    +media('md')
      position: relative

    .logo
      +media('md')
          width: 193px

    .minimal
      display: block
      height: 87px

      +media('md')
        height: 61px

      .top-bar
        padding: 15px
        text-align: center
        display: block
        height: 100%
        display: flex
        align-items: center
        justify-content: center

        &.custom-cta
          justify-content: space-between
          width: 100%

          +media('sm')
            justify-content:center

            & .custom-cta-link
              display: none

.mobile-cta-wrap
  opacity: 0
  position: fixed
  z-index: 5
  top: -61px
  left: 0
  width: 100%
  transition: transform 0.3s ease-in-out, opacity 0s linear 0.3s

  &.show
    +media('md')
      opacity: 1
      transform: translate(0, 61px)
      transition: transform 0.3s ease-in-out, opacity 0s linear 0s

.mobile-cta-nav
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  align-items: center
  background: $white
  padding: 10px 20px
  width: 100%
  border-bottom: 1px solid $grey

  p
    font-size: $xs-em
    margin: 0
    color: $nav-link

    &:last-of-type
      font-size: $xxs-em

  .icon
    width: 40px
    margin-right: 7px

  .btn
    margin-left: auto


dump_media_cache()
