@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.nav-dropdown
  width: auto

  +media('md')
    width: 100%

.dropdown-mobile
  display: none 

  +media('md')
    display: block

.dropdown-desktop
  display: block
  margin-left: 25px

  +media('md')
    display: none

.nav-item
  display: flex
  align-items: center 
  justify-content: center

  &:hover
    cursor: pointer

.dropdown-wrap
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  
  +media('md')
    background-color: $white

  a:focus + .dropdown-content-wrap
    overflow: visible
    max-height: 600px

  .nav-item
    margin-left: 0 !important

    &:hover
      .dropdown-content-wrap
        overflow: visible
        max-height: 600px

.dropdown-content-wrap
  max-height: 0px
  overflow: hidden
  position: absolute
  top: 24px
  left: 50%
  width: 260px
  transform: translate(-50%, 0)

  +media('md')
    width: 100%
    overflow: hidden
    position: relative
    top: 0
    left: 0
    transition: 0.6s max-height ease-in-out
    transform: translate(0, 0)

    &.expand
      overflow: hidden
      max-height: 600px

  &.expand, &:focus-within
    overflow: visible
    max-height: 600px

.dropdown-container
  position: relative
  background-color: $white
  padding: 26px
  width: 100%
  margin-top: 18px
  border-radius: 10px
  box-shadow: 0 0 12px 0px rgba(0,0,0,0.2)

  +media('md')
    width: 100%
    box-shadow: none
    padding: 10px 0px
    background-color: transparent
    margin-top: 3px
    border-top: 2px solid $grey
    border-radius: 0

  .caret
    width: 20px
    border: 10px solid transparent
    border-bottom: 10px solid $white
    position: absolute
    top: -18px
    left: 125px
    display: block

.dropdown-list
  ul
    padding: 0
    margin: 0
    list-style-type: none

    li
      display: flex 
      align-items: center
      margin-bottom: 15px

      &:last-of-type
        margin-bottom: 0

.link-wrap
  display: flex
  width: 100%
  align-items: flex-end

  .nav-item
    flex: 2

    .chevron-icon
      +media('md')
        display: none

.btn-expand
  background-color: transparent
  height: 25px
  border: none 
  display: flex
  flex: 1
  padding: 0
  border-radius: 0
  border-bottom: 2px solid tansparent
  justify-content: flex-end
  align-items: center
  outline: none
  position: relative

  &:hover
    border: 0
    background-color: transparent

  &:focus
    &:before
      content: ''
      width: 100%
      height: 1px
      display: block
      position: absolute
      bottom: -5px
      right: 0px
      border-bottom: 2px solid $accent
      z-index: 2

.chevron-icon
  &:after
    content: ''
    display: block
    width: 15px
    height: 15px
    background-image url('./images/chevron.svg')
    background-size: 15px
    background-repeat: no-repeat
    background-position: center right
    margin-left: 10px

  &.chevron-focus-rotate
    &:hover, &:focus-within
      &:after
        transform: rotate(180deg)

  &.rotate
    &:after
      transform: rotate(180deg)


dump_media_cache()