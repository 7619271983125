@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s ease
  opacity: 1

.fade-enter, .fade-leave-to
  opacity: 0
  transition: opacity 0.5s ease

.banner-wrap
  opacity: 0

  &.visible
    opacity: 1
    width: 100%
    position: fixed
    z-index: 10000
    bottom: 0
    background: $grey
    border-top: 1px solid $white

.banner-content
  display: flex
  align-items: center
  width: 900px
  padding: 20px
  max-width: 90%
  margin: 0 auto
  opacity: 1

  +media('xs')
    padding: 10px 5px

  .text-img
    display: flex
    align-items: center

    +media('xs')
      align-items: start
    
    img
      +media('xs')
        display: none

  .txt
    display: flex
    align-items: center
    justify-content: space-between

    +media('xs')
      display: block

  img
    width: 50px
    margin-right: 20px
    min-width: 50px

    +media('xs')
      margin-top: 10px

  p
    margin: 0 20px 0 0
    font-weight: 500
    display: inline-block

    +media('xs')
      margin-right: 0
      font-size: $xxs-txt px

  button
    margin-top: 10px
    white-space: nowrap

    +media('xs')
      padding: 5px 20px
      float: right


dump_media_cache()
