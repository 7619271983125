@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.view-wrap
  section:not(#hero)
    background-color: $bg-regular

#hero
  padding-bottom: 0

  .section-inner
    padding-top: 0
    position: relative

  .tagline
    position: relative
    z-index: 1
    margin-bottom: 0

  img.dl-bear
    display: block
    margin: 0 auto
    width: 250px

  img.mobile
    display: block
    margin: 30px auto 0
    width: 261px

#downloads
  padding-bottom: 20px

  .mobile
    text-align: center

    img
      margin: 0 auto 25px
      display: inline-block


    img.mobile-btn
      &.google
        max-width: 215px

      &.apple
        max-width: 254px

    img.devices
      margin-top: 10px
      margin-bottom: 0
      max-width: 222px

    .mobile-copy
      margin-top: 100px

  .download-row
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: top
    margin-bottom: 30px
    margin-top: 50px

    +media('sm')
      justify-content: center
      flex-direction: column
      align-items: center

    .download-item
      display: inline-block
      flex: 1
      padding: 0 10px
      max-width: 575px
      margin-bottom: 10px

      +media('sm')
        width: 100%
        padding: 0

    .btn-holder
      margin-bottom: 30px

      +media('sm')
        margin-bottom: 15px

  .copy
    margin-bottom: 30px

    +media('sm')
      margin-bottom: 20px

  .btn
    width: 100%
    margin-bottom: 6px

  .notices
    max-width: 450px
    margin: 60px auto 20px
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: space-between
    align-items: center

  .terms-policy-text
    text-align: center
    margin-top: 30px
  
dump_media_cache()
