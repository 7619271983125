@import '~@/styles/mixins.styl'
@import '~@/styles/variables.styl'

.testimony
  display: flex
  max-width: 1200px
  width: 100%
  background: #FFFFFF
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12)
  border-radius: 8px
  align-items: center
  padding: 35px 50px

  +media('sm')
    flex-direction: column
    align-items: center
    padding: 20px 15px

  img
    max-width: 136px
    width:100%
    margin-right: 20px

    +media('sm')
      margin: auto
      margin-bottom: 20px   
  
  blockquote
    margin: auto
    max-width: 800px

    .quote
      margin-bottom: 15px

      &:before 
        background: url('./images/left-quote.svg')
        margin-right: 5px

      &:after
        background: url('./images/right-quote.svg')
        margin-left: 5px
        margin-right: -15px

      &:before, &:after
        content: ''
        display: inline-block
        background-repeat: no-repeat
        background-size: contain
        width: 15px
        height: 15px

dump_media_cache()
