/* ---------------- Fonts  ----------------- */
$base        = 18
$h0          = 48
$h1          = 42
$h2          = 34
$h3          = 26
$h4          = 22
$h5          = 20
$h6          = 18
$p           = 18
$sm-txt      = 16
$xs-txt      = 14
$xxs-txt     = 12

$h0-em       = ($h0 / $base)em
$h1-em       = ($h1 / $base)em
$h2-em       = ($h2 / $base)em
$h3-em       = ($h3 / $base)em
$h4-em       = ($h4 / $base)em
$h5-em       = ($h5 / $base)em
$h6-em       = ($h6 / $base)em
$p-em        = ($p / $base)em
$sm-em       = ($sm-txt / $base)em
$xs-em       = ($xs-txt / $base)em
$xxs-em      = ($xxs-txt / $base)em

/* ---------------- Widths ---------------- */
$xs     = 767px
$min-xs = 768px
$sm     = 991px

/* ---------------- COLOURS ---------------- */

/* white */
$white            = #FFFFFF
$off-white        = #FAFAFA

/* grey */
$grey             = #EDEDED
$light-grey       = #F6F6F6
$lighter-grey     = #F9F9F9
$mid-lighter-grey = #D6D6D6
$mid-light-grey   = #C6C6C6
$mid-grey         = #888888
$mid-dark-grey    = #6E6E6E
$dark-grey        = #555555
$charcoal         = #4D4D4D
$black            = #000000
$charcoal-black   = #1A1A1A

/* blue */
$light-blue       = #DFF4F6 // legacy

/* red */
$pink             = #FEE8E8
$red              = #F76565
$vivid-red        = #ED2044
$bright-red       = #FF4263
$lighter-red      = #FFF8F9

/* green */
$lighter-green    = #FAFFF4
$light-green      = #DFF0D8
$smooth-green     = #DBE5CC
$green            = #83b84d
$mid-green        = #406227
$alt-green        = #738C62
$dark-green       = #435239

/* ---------------- SOCIAL / RANDOM ---------------- */
$twitter          = #1DA1F2
$water            = #A8E2E6
$deep-yellow      = #FFBF00
$default-blue     = #88c0fa

/* ---------------- REMEMBEAR ---------------- */
$rb-dark-blue     = #434D5B
$rb-sharp-blue    = #37A6EC
$rb-bright-blue   = #50bbff

/* ---------------- ELEMENTS ---------------- */
/* content background colors */
$bg-regular       = $white
$bg-dark          = $grey
$bg-light         = $light-grey
$bg-alt           = $dark-green
$bg-blue          = $light-blue // legacy
$bg-accent-light  = $lighter-green

/* btn background colors */
$btn-neutral      = $green
$btn-disabled     = $grey
$focus-outline    = $default-blue

/* links */
$link-regular     = $mid-green
$link-disabled    = $grey

/* navigaton */
$nav-link         = $mid-dark-grey
$nav-btn          = $mid-light-grey
$footer-text      = $mid-dark-grey

/* copy */
$body-copy        = $mid-dark-grey
$header-copy      = $dark-grey
$fine-print       = $mid-grey
$accent           = $green

/* Form */
$field-neutral    = $mid-light-grey
$field-active     = $dark-grey
$disabled         = $mid-light-grey
$disabled-border  = $mid-lighter-grey
$error-msg        = $red

/* bearatar background colours */
$light-pink =  #FF8FB8
$dark-pink =  #E4528F
$light-red =  #ED736C
$dark-red = #A72F2F
$light-orange = #FF9669
$dark-orange = #ED7219
$light-yellow = #FFDB83
$dark-yellow = #EDB219
$light-green = #9FCE70
$dark-green = #83B84D
$light-blue = #70A1CE
$dark-blue = #3069AD
$light-purple = #A193F6
$dark-purple = #5D45A4
$bear-grey = #4F4F4F
$bear-black = #121212
$bear-white = #E0E0E0