@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.container
  margin: 0
  display: block
  padding: 0

  p
    margin: 0

  .expanded
    button.faq-title.plain
      background: darken($white, 4%) !important

  button.faq-title.plain
    display: flex
    position: relative
    z-index: 1
    justify-content: space-between
    background: $white
    border-radius: 0
    padding: 20px
    width: 100%
    text-decoration: none

    &:hover, &:focus
      text-decoration: none
      background: darken($white, 2%)

    &:focus
      outline: none
      text-decoration: none

    img
      display: block
      width: 20px
      margin-left: 10px
      transition: transform 0.3s ease-out
      align-self: baseline

      &.expanded
        transform: rotate(45deg)

  .faq-content
    background: $white
    padding: 20px 50px 20px 20px
    border-bottom: 1px solid $light-grey

    >>> ul
      margin: 0
      padding-left: 20px

      li
        margin: 10px 0
        list-style-type: disc

  .slide-fade-enter-active
    transition: all .3s ease
  .slide-fade-leave-active
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0)
  .slide-fade-enter, .slide-fade-leave-to
    transform: translateY(-10px)
    opacity: 0


dump_media_cache()
