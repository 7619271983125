
ul.check-list
  position: relative
  padding: 0
  list-style-type: none

  li
    position: relative
    line-height: 1.4em
    margin: 0 0 15px 0
    padding-left: 30px

    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 19px
      height: 19px
      background-image: url('~@/images/icons/list-check.svg')
      background-size: 19px 19px

  &.yellow
    li
      &:before
        background-image: url('~@/images/icons/list-check-yellow.svg')