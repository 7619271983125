@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

button
  width: 100%
  margin: 5px auto 10px

.age-consent-wrapper
  margin: 10px 10px 5px 15px
  .checkbox
    font-size: 15px
    color: $charcoal-black

    .check
      position: absolute
      top: 0
      left: 0
      height: 25px
      width: 25px
      border-radius: 5px

    input
      &:checked ~ .check
        background-image: url('~@/images/age_consent_checkbox.svg')
        background-repeat: no-repeat
        background-position: center
        background-size: 15px
        background-color: $green
        border: none

dump_media_cache()