@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.box-wrap
  height: 100%

.box
  position: relative
  background-color: $light-grey
  border-radius: 5px
  padding: 30px 15px
  height: 100%
  text-align: center
  display: block

  +media('sm')
    display: none

  p
    margin: 20px auto 0

  &.mobile
    display: none

    +media('sm')
      display: block
      padding: 12px 15px
      text-align: left
      overflow: hidden
      background-color: $white

    &.expanded
      background-color: $light-grey

    button.faq-title.plain
      display: flex
      position: relative
      z-index: 1
      justify-content: space-between
      align-items: center
      border-radius: 0
      padding: 0
      width: 100%
      text-decoration: none

      &:hover, &:focus
        text-decoration: none

      &:focus
        outline: none
        text-decoration: none

      img
        display: block
        width: 20px
        margin-left: 10px
        transition: transform 0.3s ease-out

        &.expanded
          transform: rotate(45deg)

    .expand-content
      img
        max-width: 270px

  .slide-fade-enter-active
    transition: all .3s ease
  .slide-fade-leave-active
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0)
  .slide-fade-enter, .slide-fade-leave-to
    transform: translateY(-10px)
    opacity: 0


dump_media_cache()
