@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.chat-icon-btn
  position: fixed
  bottom: 0
  right: 0
  padding: 0
  cursor: pointer
  border: none
  background-color: transparent
  width: 70px
  height: 70px
  margin: 20px

.ai-chat-bot-container
  height: 0px
  max-height: 100vh
  border-radius: 10px
  background-color: #fff
  max-width: 430px
  position: fixed
  bottom: 0px
  right: 0px
  width: 100%
  overflow: hidden
  box-shadow: 5px 0 20px 5px rgba(0, 0, 0, 0.1)
  margin: 20px
  z-index: 9999

  &.show-chat
    height: 550px
    animation: openChatAni 0.25s ease-in

  &.hide-chat
    height: 0px
    animation closeChatAni 0.25s ease-out

  +media('sm')
    width: auto

  .message-container
    height: 100%
    flex-direction: column
    position: relative
    width: 100%
    display: flex

    .message-header
      display: flex
      height: 60px
      background-color: $green
      justify-content: flex-start
      position: relative
      flex-direction: row

      .minimize-container
        flex: 0 1 auto
        margin-left: auto
        cursor: pointer
        height: inherit

        .minimize-btn
          height: inherit
          padding: 10px

      .logo-container
        height: inherit
        flex: 0 1 auto
        flex-direction: row
        position: absolute
        left: 50%
        transform: translateX(-50%)

        .logo
          height: inherit
          width: 115px

    .message-content
      display: flex
      flex-direction: column
      flex-grow: 1
      height: 80px

      .messages
        display: flex
        flex-direction: columns 
        overflow-x: hidden
        overflow-y: auto
        padding: 15px
        position: relative
        height: 100%
        overscroll-behavior: contain

        /deep/ .fine-print
          float: left
          text-align: end
          color: $mid-grey
          font-size: 10px

        /deep/ .small
          font-size: 12.5px

        /deep/  .msg-ai-container
          display: flex
          width: 100%
          flex-direction: column
          align-items: center

          .welcome-msg-container
            display: flex
            width: 100%
            flex-direction: row
            gap: 16px
            padding: 12px 16px

            .avtr
              width: auto
              margin-bottom: 0
          
          .avtr
            display: flex
            flex-direction: row
            width: 100%
            text-align: center
            align-items: center
            margin-bottom: 10px
            gap: 5px

            .bot-res-img
              width: 24px
              height: 24px

            p
              font-size: 11px
              margin-bottom: 0

            .welcome-msg-avtr
              width: 80px
              margin: auto
              height: auto

          .msg-ai
            display: flex
            flex-direction: column
            width: 100%
            font-size: ($xs-txt)px

            p
              margin: 0

            &.welcome-msg
              flex-basis: 70%

              p
                font-size: ($xxs-txt)px

              .hello-txt
                color: $mid-grey

              .info-txt
                color: $dark-grey

            .typing-animation
              text-align: center

              span
                width: 5px
                height: 5px
                background-color: $green
                display: inline-block
                margin: 1px
                border-radius: 50%

                &:nth-child(1)
                  animation: bounce 1s infinite

                &:nth-child(2) 
                  animation: bounce 1s infinite .2s

                &:nth-child(3)
                  animation: bounce 1s infinite .4s

            .chat-bubble
              border: 1px solid $green
              position: relative
              color: $mid-dark-grey
              padding: 10px
              border-radius: 10px

              &::before
                content: ""
                width: 0px
                height: 0px
                position: absolute
                border-left: 10px solid transparent
                border-right: 10px solid transparent
                border-top: 10px solid transparent
                border-bottom: 10px solid $green
                left: 10px
                top: -20px

              &::after
                content: ""
                width: 0px
                height: 0px
                position: absolute
                border-left: 10px solid transparent
                border-right: 10px solid transparent
                border-top: 10px solid transparent
                border-bottom: 10px solid #fff
                left: 10px
                top: -18px
            
            .res-txt-bot
              border: 1px solid $green
              color: $mid-dark-grey
              padding: 10px
              border-radius: 10px
              overflow: hidden

              ul
                display: block
                list-style-type: disc
                padding-inline-start: 10px

              ol 
                display: block
                list-style-type: decimal
                padding-inline-start: 10px

        /deep/  .msg-user
          background-color: $grey
          padding: 10px
          border-radius: 10px
          overflow: hidden
          float: right
          width: 70%
          display: flex
          justify-content: space-between
          font-size: ($xs-txt)px

          .res-txt-user
            width: 100%

      #msg_form
        .input-container
          position: relative
          border-top: 1px solid $grey
          padding: 15px
          width: 100%
          display: flex

          input
            border: none
            outline: none
            resize: none
            min-height: 20px !important
            padding-right: 15px
            width: 90%
            font-size: ($xs-txt)px
            font-family: 'Montserrat'

        .send-msg
          cursor: pointer
          width: 32px
          height: 32px
          float: right
          padding: 1px
          border: none
          background-color: transparent

@keyframes bounce 
  0% 
    transform: translateY(0px)
  50% 
    transform: translateY(8px)
  100% 
    transform: translateY(0px)

@keyframes openChatAni 
  from
    height: 0px
  to
    height: 550px

@keyframes closeChatAni 
  from
    height: 550px
  to
    height: 0px

.mleft-15
  margin-left: 15px

.mtop-5
  margin-top: 5px


dump_media_cache()