@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.icon-desc
  display: flex
  flex-direction: column
  align-items: center
  text-align: center

  +media('sm')
    flex-direction: row
    text-align: left
    margin-top: 20px

  img
    max-width: 180px
    margin-bottom: 20px

    +media('sm')
      max-width: 100px
      margin-right: 10px
      margin-bottom: 0

  p
    margin: 0


dump_media_cache()
