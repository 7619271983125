.small
  font-size: 85%

.circle-num
  display: inline-block
  font-size: 16px
  line-height: 25px !important
  width: 25px
  min-width: 25px
  height: 25px
  text-align: center
  background: $accent
  border-radius: 50%
  color: $white
  line-height: 35px

  &.large
    width: 32px
    min-width: 32px
    height: 32px
    font-size: 20px
    line-height: 32px !important

.checkmark
  display: inline-block
  width: 40px
  height: 40px
  background-image: url('~@/images/submit_success.svg')
  animation: pop 0.5s linear

blockquote.quotes
  p
    line-height: 1.6em
    margin-top: 10px
    margin-bottom: 10px
    text-align: left

    &:first-child:before, &:last-child:after
      content: ''
      display: inline-block
      background-repeat: no-repeat
      background-size: 15px 10px
      width: 15px
      height: 15px

    &:first-child:before
      background-image: url('~@/images/left-quote.svg')
      margin-right: 5px

    &:last-child:after
      background-image: url('~@/images/right-quote.svg')
      margin-left: 5px
      margin-right: -15px

  &.center
    p
      text-align: center

cite
  font-style: normal
  display: block
  font-weight: bold