@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.submit-btn
  position: relative
  transition: background-color 0.3s ease
  min-height: 67px

  .text
    &.hidden
      opacity: 0

  .button-state
    background-size: 40px
    background-repeat: no-repeat
    background-position: center center
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0

    &.fade-enter-active, &.fade-leave-active
      transition: opacity 1s ease
      opacity: 1

    &.fade-enter, &.fade-leave-to
      opacity: 0

  .spinner
    background-image: url('./images/spinner.png')
    animation: rotating 1.5s linear infinite

  .checkmark
    background-image: url('./images/submit_success.svg')
    animation-fill-mode: forwards
    animation: pop 0.5s linear


dump_media_cache()
