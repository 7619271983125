@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.input-wrap
  margin-bottom: 10px

  +media('md')
    margin-bottom: 8px

input, .input, select
  position: relative
  height: 55px
  padding-top: 26px
  padding-bottom: 6px
  line-height: 1.1em
  font-size: $p-em
  overflow: visible
  z-index: 2
  background: rgba(0,0,0,0.03)
  margin-bottom: 0

  &#card-element
    padding-top: 16px

    +media('xs')
      padding-top: 18px

label
  text-align: left
  width: 95%
  position: absolute
  top: 18px
  left: 8px
  font-size: $p-em
  overflow: hidden
  z-index: 0
  transition: all, ease-in, 0.3s

  &.active
    top: 8px
    font-size: 13px
    line-height: 1.2em
    z-index: 3

    &.cc
      +media('sm')
        top: 48px

dump_media_cache()