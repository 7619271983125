.radios.custom

  input[type="radio"]
    position: absolute
    opacity: 0

  label 
    display: flex

  .radio-check
    cursor: pointer
    margin-right: 8px
    z-index: 2

.radio-check
  position: relative
  width: 18px
  min-width: 18px
  height: 18px
  background-image: url('~@/images/icons/check.svg')
  background-size: 12px
  background-repeat: no-repeat
  background-position: center
  border-radius: 50%
  border: 1px solid #c7c5c5
  background-color: white

  &.large
    width: 25px
    min-width: 25px
    height: 25px
    background-size: 15px

  &.selected
    background-color: $accent
    border: 1px solid $accent

  &.dot
    background-image: none
    
    &:before
      content: ''
      width: 50%
      height: 50%
      border-radius: 50%
      background-color: $white
      position: absolute
      top: 25%
      left: 25%

.selected
  .radio-check
    background-color: $accent
    border: 1px solid $accent