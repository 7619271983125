@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

h1
  margin-bottom: 30px

.devices
  margin: 15px auto 0
  max-width: 222px

#mobile-hero
  padding-bottom: 0
  margin-bottom: 0

  img.mobile
    display: block
    margin: auto
    max-width: 227px

#mobile-content
  .store
    margin-bottom: 50px

  img.app-store
    max-width: 254px
    margin-bottom: 15px

  img.google-play
    max-width: 215px
    margin-bottom: 15px

#hero
  padding-top: 60px
  padding-bottom: 0px

  .container
    max-width: 1100px

    .os-version
      margin-top: 6px

  .copy
    margin-bottom: 60px

    +media('sm')
      margin-bottom: 20px
      text-align: center

    .different-device
      margin-top: 20px

  .img
    display: flex
    align-items: flex-end

    .dl-bear
      display: block
      margin: 16px auto 0
      width: 250px

  .flex-center
    display: flex
    flex-direction: column
    align-items: center

#instructions
  padding-top: 40px
  padding-bottom: 80px

  +media('sm')
    padding-bottom: 20px

  .steps
    padding-top: 10px
    display: flex
    flex-wrap: wrap
    justify-content: space-around

    .step
      width: 23%
      margin: 10px 1%
      text-align: center

      +media('md')
        width:100%
        max-width: 300px

      img 
        max-width: 181px
        display: block
        margin: 20px auto

      .h6
        display: inline-block
        margin-left: 6px
        margin-bottom: 10px

#subnotes
  padding-top: 30px
  padding-bottom: 30px

  .notices
    max-width: 450px
    margin: auto
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: space-between
    align-items: center

  .terms-policy-text
    text-align: center
    margin-top: 30px

dump_media_cache()
