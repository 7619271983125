@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

$device-sprite-width = 22

.device
  width: ($device-sprite-width)px
  height: ($device-sprite-width)px
  background-image url('./images/devices.svg')
  background-repeat: no-repeat
  background-size: ($device-sprite-width * 6)px ($device-sprite-width)px
  margin-right: 6px

  &.mac
    background-position: 0 0

  &.windows
    background-position: -($device-sprite-width)px 0px

  &.ios
    background-position: -($device-sprite-width * 2)px 0px

  &.android
    background-position: -($device-sprite-width * 3)px 0px

  &.browser
    background-position: -($device-sprite-width * 4)px 0px

  &.blocker
    background-position: -($device-sprite-width * 5)px 0px

dump_media_cache()