.learn-more
  .lm-click
    border: 0
    outline: 0
    padding: 0
    background-color: transparent
    margin: 0 auto 20px auto
    display: flex
    align-items: center

    .lm-plus, plus
      display: inline-block
      width: 24px
      min-width: 24px
      height: 22px
      min-height: 22px
      margin-right: 5px
      background-image: url('~@/images/icons/plus_button@2x.png')
      background-repeat: no-repeat
      background-size: contain
      background-repeat: no-repeat
      transition: transform 0.5s ease-in-out
      transform: translateX(0px) rotate(0deg)

    .lm-text
      opacity: 1.0
      color: $link-regular
      display: inline-block

      &.fade
        transition: opacity 0.5s ease

  .lm-detail
    overflow: hidden
    height: auto
    max-height: 0
    transition: max-height .5s ease-in-out

  &.open
    .lm-click
      .lm-text
        &.fade
          opacity: 0

      .lm-plus
        transform: rotate(45deg)

        &.move
          transform: translateX(50px) rotate(45deg)
        &.far
          transform: translateX(100px) rotate(45deg)

    .lm-detail
      max-height: 1300px
