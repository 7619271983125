@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.view-wrap
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 100
  display: flex
  align-items: start
  padding-top: 0

  +media('sm')
    align-items: center

  &.fade-enter-active, &.fade-leave-active, &.minimize-enter-active, &.minimize-leave-active
    transition: opacity 0.2s ease
    opacity: 1

  &.fade-enter, &.fade-leave-to, &.minimize-enter, &.minimize-leave-to
    transition: opacity 0.2s ease
    opacity: 0

  &.minimize-leave-active
    +media('min-sm')
      transition-delay: 1s

    .grey-bg
      +media('min-sm')
        transition: all 0.3s ease-in-out
        opacity: 0

    .overlay
      +media('min-sm')
        transition: width 1.2s ease-in-out, transform 0.7s ease-in-out 0.5s, opacity 1s ease-in-out 1s
        opacity: 0
        transform: translate(-84vw, 100vh) scale(-1)

  .grey-bg
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 0
    opacity: 1
    background: rgba(0,0,0,0.5)

  .modal-wrap
    $modal-margin-top = 20vh
    $modal-margin-bottom = 5vh // ensure we scroll instead of overflowing inaccessibly off the bottom of the viewport
    width: 100%
    max-width: 550px
    text-align: center
    margin: $modal-margin-top auto 0
    max-height: 100vh - $modal-margin-top - $modal-margin-bottom
    overflow-y: auto

    +media('md')
      margin: auto
      max-height: 94vh

    &.large
      max-width: 650px

    &.xl
      max-width: 790px

    &.overlay
      max-width: 1200px

      .form-wrap
        background-color: black

        .form
          padding-bottom: 0

  .form-wrap
    display: block
    margin: 0 auto
    z-index: 1
    width: 95%
    position: relative
    overflow: hidden
    background-color: $bg-regular
    border-radius: 3px

    .form
      margin: 0px auto 0px
      padding: 30px 50px 35px

      +media('sm')
        padding: 30px 7%

  .close
    position: absolute
    top: 15px
    right: 15px
    background-image: url('./images/close-x.svg')
    background-size: 20px 20px
    background-repeat: no-repeat
    width: 20px
    height: 20px
    z-index: 3

    &:hover
      cursor: pointer

dump_media_cache()
