@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.copy
  p
    margin-bottom: 20px

.sm
  max-width: 380px
  margin: auto

dump_media_cache()
