@import '~@/styles/mixins.styl'
@import '~@/styles/variables.styl'

.counter

  +media('lg')
    min-width: 295px
    
  &:not(.counter-block)

    .timer
      display: inline-block

      .col
        display: inline
        padding-left: 5px
        padding-right: 5px

        p
          display: inline
          font-size: 0.8em

        .number
          font-size: 1.0em
          font-weight: bold

  &.counter-block
    text-align: center
    background: $charcoal
    color: $off-white
    padding: 25px 0 15px
    border-radius: 25px

    .timer
      padding-top: 20px

    .number
      font-size: 36px
      font-weight: bold
      margin-bottom: 5px
      line-height: 34px

dump_media_cache()
