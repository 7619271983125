btn(bg, color, hover-bg, hover-color)
  background-color: bg
  border: 1px solid bg
  color: color

  &:hover
    color: hover-color
    background-color: hover-bg
    border: 1px solid hover-bg

  &.hollow
    background-color: transparent
    color: bg

    &:hover
      background-color: transparent
      color: hover-bg

body
  /* Button */
  button, .btn
    btn($btn-neutral, $white, darken($btn-neutral, 5%), $white)
    font-size: $p-em
    font-family: Montserrat, Helvetica, sans-serif
    border-radius: 30px
    display: inline-block
    padding: 10px 22px
    text-align: center
    font-weight: 500

    &.plain
      background: transparent
      border: 0
      padding: 0
      margin: 0
      display: inline
      text-align: left

      &.link
        color: $link-regular
        text-decoration: underline

      &.dark
        color: $mid-dark-grey

      &:hover, &:focus
        background: transparent
        border: 0
        text-decoration: underline

    &:hover
      cursor: pointer
      text-decoration: none

    &:active, &:focus
      cursor: pointer

    &:active
      box-shadow: none

      &:focus
        outline: none

    &[disabled]
      opacity: 0.8
      color: $mid-dark-grey
      background-color: $btn-disabled
      border-color: $btn-disabled
      cursor: not-allowed

    &.mid-grey
      btn($mid-grey, $white, darken($mid-grey, 10%), $white)

    &.dark-grey
      btn($dark-grey, $white, darken($dark-grey, 10%), $white)

    &.grey
      btn($grey, $dark-grey, darken(#EBEAE8, 5%), $dark-grey)

    &.rb-blue
      btn($rb-dark-blue, $white, darken($rb-dark-blue, 8%), $white)

    &.green
      btn($green, $white, darken($green, 8%), $white)

    &.red
      btn($red, $white, darken($red, 5%), $white)

    &.dl
      $bg-icon = 39px
      $white-space = 1.7px
      $total-width = $bg-icon + $white-space

      div:before
        content: ''
        display: inline-block
        background-image: url('~@/images/apps_platform_icons@2x.png')
        background-size: 297px 39px
        width: $total-width
        height: $bg-icon
        margin-right: 7px

      > div
        display: flex
        flex-direction: row
        flex-wrap: nowrap
        justify-content: center
        align-items: center

      &.chrome
        div:before
          background-position: 0 0

      &.firefox
        div:before
          background-position: -($total-width) 0

      &.edge
        div:before
          background-position: -(($total-width)* 2) 0

      &.ios
        div:before
          background-position: -(($total-width)* 3) 0
      
      &.android
        div:before
          background-position: -(($total-width)* 4) 0
      
      &.mac
        div:before
          background-position: -(($total-width)* 5) 0
      
      &.windows
        div:before
          background-position: -(($total-width)* 6) 0

  button.link
    border: 0
    background-color: transparent
    padding: 0
    margin: 0
    display: inline
    font-weight: 400
    font-size: $p-em
    width: auto
    color: $link-regular

    &.center
      text-align: center
      margin-left: auto
      margin-right: auto
      display: block

    &.right
      margin-left: auto
      margin-right: 0
      display: block

    &:hover
      background-color: transparent
      text-decoration: underline


  .btn-lg
    border-radius: 40px
    padding: 18px 36px
    line-height: 1.33em
    font-size: $h4-em

    +media('sm')
      padding: 13px 28px
      line-height: 1.33em
      font-size: $h5-em

    &:focus
      outline: none

  .btn-full
    width: 100%

  .btn-decor
    position: relative
    
    &:after
      content: ''
      position: absolute
      bottom: -30px
      left: -29px
      display: block
      width: 180px
      height: 70px
      z-index: 8
      background-image: url('../../../static/images/sale/lights_snow.png')
      background-size: 180px 70px
      background-repeat: none

  .btn-ribbon
    position: relative

    &:before
      position: absolute
      top: -7px
      right: -21px
      z-index: 8
      content: ''
      background-image: url('../../../static/images/sale/ribbon_lights.png')
      background-size: 135px 135px
      background-position: 0px 0px
      background-repeat: no-repeat
      width: 135px
      height: 135px

  .submit-btn
    &:focus
      outline: none
      box-shadow: 0px 0px 10px $focus-outline

  .mobile-btn
    width: 100%
    margin-bottom: 15px

    &.app-store
      max-width: 254px

    &.google-play
      max-width: 215px

$media (max-width: 900px)
  body
    button, .btn
      font-size: ($sm-txt)px

    .btn-lg
      font-size: $p-em

$media (max-width: 480px)
  .btn-lg
    padding: 13px 20px
    font-size: $h6-em
