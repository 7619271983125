@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

button
  width: 100%
  margin: 5px auto 10px

.alert-danger
  margin: 20px auto 15px

dump_media_cache()