@import '~@/styles/mixins.styl'
@import '~@/styles/variables.styl'

#hero
  padding-bottom: 0
  overflow: hidden

  .strike
    text-decoration: line-through
    color: $mid-grey

  &.tron // during sale
    background-color: black
    color: white

    h1, .h1, p
      color: white

    .btn
      color: black
      background-color: #6ec8c4
      border-color: #6ec8c4

      &:hover
        opacity: 0.9

  #trail // rule only needed during sale?
    display: block
    height: 30.7%
    width: 400px
    position: absolute
    top: 49.2%
    left: -400px
    z-index: 0
    background-image: linear-gradient(to right, black, #254e4d)

    &.dark
      background-image: linear-gradient(to right, black, #1e4140)
      height: 31%

  .content
    max-width: 1000px
    margin:auto
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: row

    +media('sm')
      flex-direction: column

  .sale-copy
    margin-top: 20px

    .strike
      color: #B42025
      text-decoration: line-through

  .copy
    flex: 1 1 0
    max-width: 450px
    position: relative
    bottom: 70px
    z-index: 1

    +media('sm')
      flex-basis: auto
      text-align: center
      bottom: 0
      margin-bottom: 40px

    .btn-holder
      display: inline-block
      margin-top: 15px
      width: 100%

  .img-holder
    flex: 1 1 0
    width: 100%
    max-width: 600px
    align-self: flex-end
    position: relative

    +media('sm')
      flex-basis: auto

    &:before
      top: 0
      content: ""
      background-color: transparent
      display: block
      padding-top: 100%
      width: 100%


    .bear

      video
        width: 100%
        position: absolute
        bottom: 0
        left: 0
        display: block
        background: transparent url('./images/hero-placeholder.png') center bottom no-repeat
        background-size: 100%

      .img
        position: absolute
        bottom: 75px
        display: block

        &.offset-position
          left: 10%

          +media('xs')
            width: 80%

    >>>.device
      display: none
      position: absolute
      bottom: -10%
      left: 0
      width: 46%

      +media('sm')
        display: block

      +media('xs')
        width: 42%
        left: 7%

      >>>&.ios
        max-width: 230px
      &.android
        max-width: 227px

  .position
    .img-holder
      &:before
        +media('xs')
          padding-top: 90%

      .bear
        +media('sm')
          position:relative
          left: 10%

        video, .img
          +media('xs')
            width: 92%

#recommended
  padding-top: 45px
  padding-bottom: 45px

  +media('sm')
    padding-top: 20px
    padding-bottom: 20px

  .content
    display: flex
    justify-content: space-between
    flex-direction: row
    flex-wrap: nowrap
    align-items: center

    +media('sm')
      flex-wrap: wrap
      justify-content: center

    img
      width: 32%
      max-width: 160px
      min-width: 100px

.app-list
  margin: 80px auto 30px
  max-width: 280px
  display: flex
  align-items: flex-end
  justify-content: space-between

  .app
    flex: 1 1 0
    text-align: center

    p
      opacity: 0.7

    img
      height: 35px
      margin-bottom: 6px

#private, #world, #content-list
  .content
    display: flex
    justify-content: space-between
    align-items: center
    flex-direction: row

    +media('md')
      flex-direction: column

    .img-holder
      width: 100%
      flex: 1 1 0

      +media('md')
        flex-basis: auto

      img
        display: block
        width: 100%

        +media('md')
          width: 85%
          max-width: 350px
          margin: auto

    .copy
      width: 100%
      flex: 1 1 0

      +media('md')
        flex-basis: auto

      &:last-of-type
        margin-left: 20px


      .img-text
        display: flex
        align-items: flex-start
        margin: 40px 0
        max-width: 500px

        &:last-of-type
          margin: 0

        >>> img
          width: 100px
          min-width: 100px
          margin: 0 30px 0 0

          +media('md')
            width: 75px
            min-width: 75px

#private
  h2
    margin-bottom: 40px

#FAQ
  ul.faq
    max-width: 800px
    width: 100%
    list-style-type: none
    border: 1px solid lighten($mid-light-grey, 40%)
    background: $white
    padding: 0
    margin: 50px auto 0

    > li:nth-of-type(even)
      padding: 0
      margin: 0

#private
  .img-holder
    order: 2

    +media('md')
      order: 1

  .copy
    margin-right: 40px
    order: 1

    +media('md')
      order: 2
      margin: 0

#world
  .copy
    margin-left: 40px

    +media('md')
      margin: 0

#audit
  .content
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    flex-direction: row

    +media('sm')
      flex-direction: column

  .copy
    flex: 1 1 0
    order: 1
    margin-right: 30px

    +media('sm')
      flex-basis: auto
      width: 100%
      max-width: none
      margin: 30px auto 0
      order: 2

    .copy-inner
      max-width: 450px
      margin: auto

      +media('sm')
        max-width: none

  .img-holder
    height: 100%
    flex: 1 1 0
    order: 2
    max-width: 550px

    +media('sm')
      flex-basis: auto
      order: 1

    img
      width: 100%
      margin: auto
      display: block

      +media('sm')
        width: 80%

#download
  .copy
    max-width: 600px
    +media('sm')
      max-width: 350px

  .btn, .mobile-btn
    margin-top: 15px

.btn-mobile
  max-width: 250px
  display: block
  margin: 20px auto 0

dump_media_cache()
