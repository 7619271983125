@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

#network
  p
    padding-bottom: 1em

  img
    display: block
    max-width: 464px
    padding-right: 10px

    +media('md')
      margin-bottom: 30px
      padding-right: 0
      max-width: 100%
      width: 100%
  
  .text
    +media('md')
      max-width: 100%

  .world
    +media('md')
      max-width: 400px
      margin: 0 auto 20px
      
  .countries-container
    width: 100%
    margin: auto

    +media('sm')
      width: 100% !important

dump_media_cache()
