@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.view-wrap
  padding-top: 0
  background-color: $bg-dark

#footer
  .container
    width: 100%
    max-width: 850px

  a, p, li
    font-size: 14px

  img
    max-width: 164px

  h6
    font-size: 16px
    color: $mid-dark-grey
    text-transform: uppercase

  .footer-content
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: space-between

  .footer-column
    text-align: left
    margin-bottom: 25px
    padding-right: 10px
    width: auto
    min-width: 150px
    max-width: 200px

    +media('sm')
      min-width: 100px
      max-width: 120px

    .coming-soon
      h6
        color: $link-disabled

    ul
      list-style-type: none
      margin: 0
      padding: 0

      &.horizontal
        display: flex
        flex-direction: row

      li
        margin-top: 0.8em

        a
          color: $footer-text

          &.icon-parent
            opacity: 1
            margin-right: 15px

            &:hover
              opacity: 0.9
              text-decoration: none

            .icon
              height: 18px

            .icon-instagram
              height: 19px


  #essentials
    margin-top: 50px

    .apps
      display: flex
      justify-content: center
      margin-top: 10px

      .app
        display: flex
        flex-direction: column
        margin: 0 15px

        img
          height: 50px
          width: 50px
          margin: 10px auto

  #signature
    padding-top: 130px

    img
      opacity: 0.7
      margin-bottom: 15px

  .minimal
    padding: 20px 0

    p
      display: inline-block
      margin: auto

  .pi
    font-size: 10px
    position: fixed
    bottom: 10px
    right: 18px

    +media('sm')
      font-size: 9px

  .pi-video
    background-image: url('https://s3.amazonaws.com/tunnelbear/images/pi3.gif')
    background-size: 100% 100%
    position: fixed
    top: 0
    bottom: 0
    width: 100%
    height: 100vh
    z-index: 101

dump_media_cache()
