@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

#captcha-form
  width: 100%
  border: none
  margin-top: 30px
  margin-bottom: 50px
  height: 500px // cloudflare's expanded captcha form is min 322 x 500px

  +media('sm')
    $captcha-margin-hack = 14px  // on very small screens, the modal margins don't leave quite enough room
    width: 'calc(100% + %s)' % $captcha-margin-hack
    margin-left: -($captcha-margin-hack / 2)
    margin-right: -($captcha-margin-hack / 2)

dump_media_cache()
