@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

#audit
  .lg-screen
    +media('sm')
      display: none

  .sm-screen
    display: none

    +media('sm')
      display: flex
      
  .content
    display: flex
    justify-content: space-between
    align-items: center
    flex-direction: row

    +media('sm')
      flex-direction: column
      justify-content: center

  .copy
    order: 1
    max-width: 450px
    margin-right: 40px
    
    +media('sm')
      max-width: 330px
      margin: 30px auto 0
      order: 2

    >>> img 
      height: auto
      max-width: 120px
      width: 100%

    p
      max-width: 400px
      margin: auto

  .img-holder
    order: 2
    max-width: 500px

    +media('sm')
      order: 1

     >>> img
      width: 100%
      margin: auto
      margin-left: 30px

      +media('sm')
        width: 80%

dump_media_cache()