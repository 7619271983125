@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

#platforms
  padding: 0

.platforms
  text-align: center

.platform-wrap
  display: inline-block
  padding: 30px 8px
  text-decoration: none
  vertical-align: bottom

  &.router-link-active
    background: lighten($bg-alt, 20%)

  p
    margin: 5px auto 0

.platform
  width: 50px
  height: 50px
  background-image: url('./images/apps_platforms_icons@2x.png')
  background-repeat: no-repeat
  background-size: cover
  margin: 0 30px

  +media('sm')
    width: 33px
    height: 33px
    background-size: 247px
    margin: 0 auto

  &.ios
    background-position: -10px

    +media('sm')
      background-position: -7px

  &.android
    background-position: -70px 0

    +media('sm')
      background-position: -47px

  &.mac
    background-position: -130px 0

    +media('sm')
      background-position: -87px

  &.windows
    background-position: -190px 0

    +media('sm')
      background-position: -127px

  &.browser
    background-position: -250px 0

    +media('sm')
      background-position: -167px

  &.blocker
    background-position: -310px 0

    +media('sm')
      background-position: -207px

#apps
  &>>> #app-wrap
    .header
      font-weight: 600
      font-size: $p-em

    #main-cta
      text-align: center

      h1
        margin-bottom: 40px
        
        +media('sm')
          max-width: 400px
          margin: 40px auto

      p
        max-width: 620px
        margin: 20px auto

        +media('sm')
          max-width: 90%

      img.main-img
        width: 90%
        max-width: 659px
        margin: 0 auto

      img.main-img.smaller
        max-width: 500px

      .download-row
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: top
        max-width: 800px
        margin: 40px auto 0

        +media('sm')
          justify-content: center
          flex-direction: column
          align-items: center

        .download-item
          display: inline-block
          flex: 1
          padding: 0 10px
          max-width: 575px
          margin-bottom: 15px

          +media('sm')
            width: 100%
            padding: 0

          .btn
            width: 100%

          .fine-print
            margin: 5px 0 0 0
            max-width: 100%

    .btn-holder
      margin-bottom: 30px

      +media('sm')
        margin-bottom: 15px

    // #features
    //   .copy-wrap
    //     margin-top: 40px

    //   h2
    //     text-align: center

    //   img
    //     max-width: 440px

    //   .desc
    //     width: 100%
    //     padding-left: 20px

    //     +media('md')
    //       padding-left: 0

    //   .feature-wrap
    //     position: relative
    //     margin-bottom: 40px
    //     display: flex
    //     align-items: center

    //     +media('md')
    //       text-align: center
    //       flex-direction: column
    //       max-width: 570px
    //       margin: 0 auto 30px

    //     &:nth-of-type(1)
    //       .feature-icon
    //         background-position: -10px 0

    //         +media('md')
    //           background-position: 0 0

    //     &:nth-of-type(2)
    //       .feature-icon
    //         background-position: -115px 0

    //         +media('md')
    //           background-position: -105px 0

    //     &:nth-of-type(3)
    //       .feature-icon
    //         background-position: -220px 0

    //         +media('md')
    //           background-position: -210px 0

    //     &:nth-of-type(4)
    //       .feature-icon
    //         background-position: -325px 0

    //         +media('md')
    //           background-position: -315px 0

    //     &:nth-of-type(5)
    //       .feature-icon
    //         background-position: -430px 0

    //         +media('md')
    //           background-position: -420px 0

    //   .feature-icon
    //     background-size: 535px
    //     background-repeat: no-repeat
    //     width: 120px
    //     height: 120px

    //     +media('md')
    //       margin: 0 auto 10px
    //       width: 115px
    //       height: 115px

dump_media_cache()
