@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

.view-content
  display: flex
  min-height: 80vh !important

.content-horizontal
  position: relative
  width: 100%
  display: flex
  align-items: center
  height: inherit
  max-height: 980px

  .horizontal-inner
    width: 100%

.container
  max-width: 100%
  width: 100%
  padding: 0 25px

#top-ribbon
  padding-top: 0
  padding-bottom: 0
  height: 0px
  position: relative

  +media('sm')
    height: auto
    padding-bottom: 15px

  .section-inner
    overflow: hidden

  .floating-title
    padding-top: 4%
    position: relative
    left: 15%
    z-index: 2
    width: 100%

    +media('sm')
      padding-top: 10px
      left: auto

    h1
      padding: 0 22px
      margin: auto
      text-align: left

      +media('sm')
        text-align: center
        font-size: 28px

#bear-images
  padding: 0
  position: relative
  z-index: 2

  .bears
    display: flex
    align-items: flex-end

  .bear-pic
    flex: 1 1 0
    display: inline-block
    position: relative
    margin: 0 auto
    margin-top: auto

    &.item-yearly
      &:before
        display: block
        top: 0
        content: ""
        background-color: transparent
        height: 0
        padding-top: 78%
        width: 100%

        +media('sm')
          display: none

    img
      &.visible-xs
        display: none

        +media('sm')
          display: block

      &.hidden-xs
        display: block

        +media('sm')
          display: none

      &.free
        width: 26%
        max-width: 80px
        margin: 0 auto 0px

      &.monthly
        width: 55%
        max-width: 170px
        margin: 0 auto 0

      &.yearly
        display: block
        width: 100%
        max-width: 450px
        margin: 0 auto -38%
        position: absolute
        top: 0
        left: 0

      &.free, &.monthly, &.yearly
        +media('sm')
          position: relative
          width: auto
          max-width: 100%
          height: 260px
          margin-bottom: -75px

#bear-plans
  background-color: $bg-regular
  text-align: center
  padding: 0 0 70px
  position: relative
  overflow: visible

  +media('sm')
    padding-bottom: 40px

  .content
    padding-top: 5px
    max-width: 250px
    margin: auto
    display: flex
    flex-direction: column
    flex-wrap: nowrap
    height: 100%

    +media('sm')
      padding-top: 0

  .item-column
    position: relative
    z-index: 3

  .save-circle
    position: absolute
    right: 12%
    top: -30px
    background-color: $mid-dark-grey
    width: 72px
    height: 72px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center

    +media('md')
      width: 60px
      height: 60px

    +media('sm')
      display: none

    .save
      color: $white
      font-weight: 700
      font-size: 20px
      text-align: center
      text-transform: uppercase
      line-height: 1em
      margin: 0
      transform: rotate(15deg)

      +media('md')
        font-size: 18px

  h2
    order: 1
    margin-bottom: 20px
    font-weight: 700

    +media('sm')
      display: none

  p.copy
    margin-bottom: 15px

  .copy
    order: 2

  ul
    text-align: left
    margin: 0 auto

    +media('sm')
      order: 3

  .pricing
    order: 3
    margin: 15px 0 10px

    +media('sm')
      order: 2
      margin-top: 0px

    .amount
      font-size: 1.8em
      font-weight: 600

      +media('sm')
        font-size: 1.6em

  .btn
    width: 100%
    display: block
    align-self: flex-end
    margin: auto auto 0
    order: 4

    +media('sm')
      max-width: 200px

  .payment-info
    padding: 30px 15px

    img.payment-img
      width: 100%
      max-width: 253px
      display: block
      margin: 10px auto

/* ---------- Mobile Selections ---------- */
#bear-images, #bear-plans
  .select-free
    +media('sm')
      .item-monthly
        display: none

      .item-yearly
        display: none

      p.copy
        margin-top: 15px

      .btn
        margin-top: 40px

  .select-monthly
    +media('sm')
      .item-free
        display: none

      .item-yearly
        display: none

  .select-yearly
    +media('sm')
      .item-free
        display: none

      .item-monthly
        display: none

.mobile-menu
  width: 95%
  max-width: 360px
  margin: 15px auto 10px
  border-radius: 30px
  overflow: hidden
  border: 1px solid $mid-dark-grey
  display: none

  +media('sm')
    display: flex

  button
    width: 33.3333%
    padding: 10px
    border-radius: 0
    border: none
    background-color: transparent
    color: $mid-dark-grey
    border-right: 1px solid $mid-dark-grey
    outline: 0

    &:focus
      outline: 0

    &:last-of-type:not(.selected)
      border-right: none

    &.selected
      background-color: $mid-dark-grey
      color: $white



dump_media_cache()
