@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

p
  margin: 30px 0

section 
  position: relative

  .anchor
    position: absolute
    top: -110px

.pull-quote
  color: $accent
  font-size: 2.0em
  line-height: 1.3em
  margin: 80px 0

  &::before
    content: '\201c'

  &::after
    content: '\201d'

  +media('sm')
    font-size: 1.5em
    margin: 30px 0 50px

.caption-img
  margin: 50px auto

  img
    margin-bottom: 20px

    +media('sm')
      margin-bottom: 10px

  p
    margin: 20px 0 0
    font-size: 0.9em

.list
  margin-top: 30px

  +media('sm')
    margin-top: 15px

>>>.countries-container
  margin-top: 20px

  .countries
    margin-left: 5%

    .country
      width: 33%
      float: left
      margin-bottom: 3px

      +media('md')
        width: 50%

#hero
  padding-bottom: 0px

  img 
    display: block
    margin-top: 30px

  h1
    span
      display: block
      font-size: 0.7em

#contents
  .table
    margin-top: 40px

    a
      color: $body-copy
      font-weight: 500
      display: flex
      align-items: center
      background-color: transparent
      padding: 10px
      border-radius: 3px
      margin-bottom: 6px
      text-decoration: none

      &:hover, &:focus
        background-color: $light-grey
        color: $dark-grey
        outline: none

      &::before
        content: ''
        display: inline-block
        position: relative
        background-image: url('./images/down-arrow.svg')
        background-position: center
        background-size: 20px 20px
        width: 20px
        min-width: 20px
        height: 20px
        min-height: 20px
        background-repeat: no-repeat
        margin-right: 10px

#what
  .layout-img
    max-width: 542px

#why
  padding-bottom: 10px

#reasons
  .row
    margin-top: 40px

    +media('sm')
      margin-top: 0

  .col
    +media('sm')
      margin: 5px 0

#how
  h2
    max-width: 550px
    margin: auto
    margin-bottom: 30px

#with-tunnelbear
  .layout-img 
    max-width: 1129px
    display: block
    margin: 30px auto 0

    &.desktop
      display: block

      +media('sm')
        display: none
    
    &.mobile
      display: none
      max-width: 361px 

      +media('sm')
        display: block

#client
  .pull-quote
    margin-bottom: 0

#install
  .btn-wrap
    margin-top: 40px

    +media('sm')
      margin-top: 20px

#use
  .layout-img
    max-width: 520px

  .caption-img
    margin-bottom: 0px

#faq
  p
    margin: 8px 0

  ul.faq
    max-width: 800px
    width: 100%
    list-style-type: none
    background: $white
    padding: 0
    margin: 50px auto 0

    > li:nth-of-type(even)
      padding: 0
      margin: 0

    +media('sm')
      margin-top: 20px

#tunnelbear
  img
    max-width: 1083px

#download
  img
    max-width: 120px
    margin-bottom: 20px

  a
    margin-top: 20px

dump_media_cache()
