input
  color: $black

  :-moz-placeholder, ::-moz-placeholder, ::-webkit-input-placeholder, :-ms-input-placeholder
    color: $body-copy

fieldset 
  border: none
  margin: 0
  padding: 0
  width: 100%
  min-width: 0

  legend
    display: table
    margin-bottom: 10px

.form-area
  .input, input, select, textarea
    line-height: 1.6em
    font-family: Montserrat, helvetica, sans-serif

  &.auth
    input
      margin-bottom: 0px

  input, .input
    margin-bottom: 4px
    padding: 8px
    font-size: $p-em
    background-color: $off-white
    border: 1px solid $field-neutral
    border-radius: 3px
    height: 36px

    &[type=radio], &[type=checkbox]
      position: relative
      top: -2px
      margin-right: 5px

      &:after
        content: ''
        height: 40px

    &[type="number"].no-spinner
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0

  input:focus, select:focus, textarea:focus
    outline: none
    border: 1px solid $field-active

  label
    width: 100%
    display: block

    input, .input
      width: auto
      display: inline-block

  .radios, .checkboxes
    label
      padding: 5px
      margin-bottom: 5px

      input, .input
        width: auto

  .checkbox
    display: block
    position: relative
    padding-left: 35px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    display: flex
    align-items: center
    height: 25px

    &:hover
      .check
        background-color: darken($light-grey, 5%)

    &.error
      color: $red

      .check
        border-color: $red

    .check
      position: absolute
      top: 0
      left: 0
      height: 25px
      width: 25px
      background-color: $light-grey
      border-radius: 2px
      border: 1px solid $mid-light-grey

    input
      position: absolute
      opacity: 0
      cursor: pointer
      height: 0
      width: 0

      &:checked ~ .check
        background-image: url('~@/images/submit_success.svg')
        background-repeat: no-repeat
        background-position: center 
        background-size: 25px

  .form-section
    margin-bottom: 5px
    display: flex
    flex-flow: row wrap

    .error-field
      flex: 1 auto
      // z-index: 3

    .input-wrap
      +media('md')
        flex: 1 100%

    +media('md')
      .error-left
        order: 1
      .error-middle
        order: 3
      .error-right
        order: 5

      .input-left
        order: 2
      .input-middle
        order: 4
      .input-right
        order: 6

  .form-row
    margin-bottom: 5px

    +media('md')
      display: block

    &.button-wrap
      +media('md')
        display: flex

  .button-wrap
    flex-grow: 1
    flex-basis: 0
    button, .btn
      margin-left: 2px
      margin-right: 2px

      &:first-of-type
        margin-left: 0

      &:last-of-type
        margin-right: 0

    button, .btn
      width: 100%


  .form-group
    margin-bottom: 5px

  .submit-group
    margin-top: 5px

  &.small
    max-width: 300px
    margin: 0 auto

  input[type="radio"], input[type="checkbox"]
    height: auto

  input, select, .input
    width: 100%
    background-color: $off-white

  input[type=number].no-spinner::-webkit-outer-spin-button,
  input[type=number].no-spinner::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  input[type=number].no-spinner
    -moz-appearance:textfield

  select
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    padding: 8px
    padding-right: 28px
    border: 1px solid $field-neutral
    border-radius: 3px
    color: grey
    font-size: $p-em
    cursor: pointer
    height: auto

    &.selected
      color: black

  .select-wrap
    background-color: $off-white
    border-radius: 3px
    position: relative

    select
      position: relative
      background: transparent
      z-index: 1

    .caret
      z-index: 0
      position: absolute
      top: 47%
      right: 12px
      display: inline-block
      width: 0
      height: 0
      margin-left: 2px
      vertical-align: middle
      border-top: 4px solid
      border-right: 4px solid transparent
      border-left: 4px solid transparent

  .textarea-wrap
    display: block
    width: 100%

    .count
      float: right
      font-size: 12px

      .red
        color: $error-msg

  textarea
    padding: 6px
    font-size: $p-em
    border: 1px solid $field-neutral
    border-radius: 3px
    resize: vertical
    width: 100%
    height: 100px

  textarea:focus
    outline: none
    border: 1px solid $field-active


  .form-constrain
    width: 460px
    max-width: 100%
    margin: 0 auto


  .alternatives
    display: block
    margin: 12px 0 5px


  .bad-field:not(.select-wrap), .StripeElement--invalid
    border: 1px solid $error-msg
    background: rgba(0,0,0,0.01)


  .bad-field.select-wrap select
    border: 1px solid $error-msg
    background: rgba(0,0,0,0.01)

  .half
    &:first-of-type
      margin-bottom: 10px

    input, .input
      width: 100%

  .wait
    position: absolute
    z-index: 1
    top: 50%
    left: 50%
    width: 27px
    margin: -13.5px 0 0 -13.5px

  button.wait-btn[disabled]:not(.finished), .btn.wait-btn[disabled]:not(.finished)
    text-indent: 200%
    overflow: hidden

  button[type="submit"]
    width: 100%

  .input-wrap
    position: relative
    display: block
    flex-grow: 1
    flex-basis: 0

    +media('md')
      margin: 0 0 5px 0

      +.input-wrap

        &:last-of-type
          margin-left: 0

    &+.input-wrap

      &:last-of-type
        margin-left: 6px

    input, textarea
      width: 100%

    &.input-lg
      margin-bottom: 10px

      +media('md')
        margin-bottom: 8px

      input, .input, select
        position: relative
        height: 55px
        padding-top: 16px
        padding-bottom: 16px
        line-height: 1.1em
        font-size: $p-em
        overflow: visible
        z-index: 2
        background: rgba(0,0,0,0.03)
        margin-bottom: 0

  .error-wrap
    display: flex
    width: 100%

  .show-error
    input, .input, select, textarea
      border: 1px solid $red

    .field-error, &.field-error
      display: inline-block

  .field-error
    flex-direction: column
    position: static
    width: 100%
    bottom: 100%
    left: 0
    display: none
    padding: 8px 12px
    text-align: left
    border-radius: 3px
    color: $white
    background-color:  $error-msg
    transform: scaleY(1)
    opacity: 1
    z-index: 2
    margin-bottom: 9px

    &.desktop
      display: block

    &.mobile
      display: none

    &.basic-error
      position: relative
      bottom: auto
      margin-top: 0
      left: auto
      padding: 8px 12px

    button
      display: none

    a
      color: $white
      text-decoration: none
      font-weight: bold

      &:hover
        color: $white
        text-decoration: underline

    &.error-right
      left: auto
      right: 0

      &:before, &:after
        left: auto
        right: 15px

        +media('md')
          left: 15px

    &.error-middle
      text-align: center

      &:before, &:after
        left: 50%
        margin-left: -4px

        +media('md')
          left: 15px

    &.no-pointer
      display: block
      width: 100%
      text-align: center

      &:before, &:after
        display: none

    &:before
      content: ''
      position: absolute
      left: 15px
      bottom: -8px
      z-index: 1
      width: 0
      height: 0
      border-left: 8px solid transparent
      border-right: 8px solid transparent
      border-top: 8px solid $error-msg

+media('sm')
  .field-error
    font-size: 15px

    &.desktop
      display: none

    &.mobile
      display: block

