@import '~@/styles/variables.styl'
@import '~@/styles/mixins.styl'

$tunnels = 49 //number of flags in the image file. we deleted india but we did not remove its flag.
$flags-height = ($tunnels * 15)


.countries
  list-style-type: none
  margin: 0
  padding: 0
  display: flex
  flex-wrap: wrap

.country
  display: flex
  flex-direction: row
  align-items: center
  margin-bottom: 15px
  width: 10px

  +media('sm')
    margin-bottom: 3px

  &.rows
    width: 25%
    
    +media('sm')
      width: 50%

    &.flag-hidden
      display: none

  .flag
    background-image: url('./images/countries.png')
    background-size: 20px ($flags-height)px
    background-repeat: no-repeat
    min-width: 20px
    width: 20px
    height: 15px
    margin-right: 5px

    @media(-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi)
      background-image: url('./images/countries@2x.png')

  p
    margin: 0
    font-size: $xs-em

    +media('xs')
      font-size: $xxs-em

dump_media_cache()
