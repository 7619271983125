@import '~@/styles/mixins.styl'
@import '~@/styles/variables.styl'

#apps
  padding: 0
  overflow: hidden
  min-height: 700px
  height: 90vh
  max-height: 750px

  +media('md')
    min-height: 250px
    height: auto
    max-height: auto

  .section-inner
    max-width: 1800px
    padding: 0

  .content
    display: flex
    justify-content: space-between
    align-items: stretch
    flex-wrap: nowrap
    height: 100%

    +media('sm')
      flex-wrap: wrap
      justify-content: center

    .img-holder
      flex: 1 1 0
      position: relative
      width: 100%
      max-width: 100%
      bottom: 0

      +media('sm')
        order: 2
        width: 50%
        max-width: 50%
        display: flex
        align-items: flex-end

        &:first-of-type
          justify-content: flex-end

      img
        position: absolute
        bottom: 0

        +media('sm')
          height: auto
          position: relative

      &.apple, &.other
        img
          &.laptop
            transition-delay: 0.7s

            +media('sm')
              width: 630px
              min-width: 630px
              right: -24%
              left: auto
              bottom: -40px

            +media('xs')
              width: 470px
              min-width: 470px
              right: -40%
              left: auto

          &.tablet
            transition-delay: 0.4s

            +media('sm')
              width: 330px
              min-width: 330px
              right: auto
              left: -15%
              bottom: -13%

            +media('xs')
              width: 230px
              min-width: 230px
              left: -20%

          &.mobile
            transition-delay: 0.1s
            z-index: 2
            bottom: 0
            +media('sm')
              width: 120px
              min-width: 120px
              right: auto
              left: 0px

            +media('xs')
              width: 90px
              min-width: 90px

      &.apple
        &:first-of-type
          order: 1

        &:last-of-type
          order 3

        +media('sm')
          &:first-of-type
            order: 2

          &:last-of-type
            order 3

        img
          &.laptop
            width: 1028px
            min-width: 1028px
            right: 0
            left: auto
            bottom: 0

          &.tablet
            width: 594px
            min-width: 594px
            right: auto
            left: 17%
            bottom: 0

          &.mobile
            width: 230px
            min-width: 230px
            right: auto
            left: 20px

      &.other
        &:first-of-type
          order 3

        &:last-of-type
          order: 1

        +media('sm')
          &:first-of-type
            order 2

          &:last-of-type
            order: 3

        img
          &.laptop
            width: 1093px
            min-width: 1093px
            right: auto
            left: -10%
            bottom: 0

            +media('xs')
              right: -180px
              left: auto

          &.tablet
            width: 622px
            min-width: 622px
            right: 22%
            left: auto
            bottom: 0px

          &.mobile
            width: 227px
            min-width: 227px
            right: 20px

  .element
    order 2
    padding: 90px 0 100px
    max-width: 390px
    flex: 1 1 0
    min-width: 305px
    margin: auto 0

    +media('sm')
      order 1
      max-width: none
      margin: 0 30px
      display: block
      padding: 50px 0 10px
      flex: 1 100%

    .copy
      +media('sm')
        max-width: 420px
        margin: auto

dump_media_cache()